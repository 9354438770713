//objeto de pagina de blogs

const data={
    noticias:[
      {
        img: 'card1.png',
        titulo: "titulo de la noticia",
        contenido: `La guerra ebbe inizio quando il Sacro Romano 
        Impero cercò d'imporre l'uniformità religiosa sui suoi domini. 
        Gli Stati protestanti del nord, indignati per la violazione dei 
        loro diritti acquisiti con la pace di Augusta, si unirono formando
         l'unione evangelica. L'impero contrastò immediatamente questa lega, 
         percependola come un tentativo di ribellione, suscitando le reazioni 
         negative di tutto il mondo protestante. La Svezia intervenne nel 1630,
          lanciando un'offensiva su larga scala nel continente. La Spagna, 
          intenzionata a piegare i ribelli olandesi, intervenne con il pretesto 
          di aiutare il suo alleato dinastico, l'Austria. Temendo l'accerchiamento 
          da parte delle due grandi potenze degli Asburgo, la cattolica Francia 
          entrò nella coalizione a fianco dei territori protestanti tedeschi per 
          contrastare l'Austria.`,
      },
      {
        img: 'img16.png',
        titulo: "Titulo de la noticia",
        contenido: `Lorem ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis
        nostrud exercitation ullamco laboris nisi ut
        aliquip ex ea commodo consequat. Lorem
        ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis
        nostrud exercitation ullamco laboris nisi ut
        aliquip ex ea commodo consequat.Lorem
        ipsum`,
      },
      {
        img: 'img16.png',
        titulo: "Titulo de la noticia",
        contenido: `Lorem ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis
        nostrud exercitation ullamco laboris nisi ut
        aliquip ex ea commodo consequat. Lorem
        ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna
        aliqua. Ut enim ad minim veniam, quis
        nostrud exercitation ullamco laboris nisi ut
        aliquip ex ea commodo consequat.Lorem
        ipsum`,
      },
    ],
    posts:[
        {
            img: 'card1.png',
            nombre: "30 anni",
            informacion:`La guerra ebbe inizio quando 
            il Sacro Romano Impero cercò d'imporre l'uniformità 
            religiosa sui suoi domini. Gli Stati protestanti del nord, 
            indignati per la violazione dei loro diritti acquisiti con la 
            pace di Augusta, si unirono formando l'unione evangelica. L'impero 
            contrastò immediatamente questa lega, percependola come un tentativo 
            di ribellione, suscitando le reazioni negative di tutto il mondo protestante. 
            La Svezia intervenne nel 1630, lanciando un'offensiva su larga scala nel continente. 
            La Spagna, intenzionata a piegare i ribelli olandesi, intervenne con il pretesto di 
            aiutare il suo alleato dinastico, l'Austria. Temendo l'accerchiamento da parte delle 
            due grandi potenze degli Asburgo, la cattolica Francia entrò nella coalizione a fianco 
            dei territori protestanti tedeschi per contrastare l'Austria.`,
            dia:"1",
            mes:"1",
            anio:"2025",
            categoria:"1"
            
          },
          {
            img: 'card3.png',
            nombre: "Lin",
            informacion:`Linda Linda Linda Linda Lindaaaaaaaaaaaaaa
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa`,
            dia:"1",
            mes:"1",
            anio:"2026",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia3",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia4",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia5",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia6",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia7",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia8",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia9",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
    ],
    sabiasPosts:[
        {
            img: 'card1.png',
            nombre: "Los heramnos Dostoyevski",
            informacion:`Los hermanos Karamázov (en ruso: Братья Карамазовы, Brátya 
            Karamázovy, pronunciado es la última novela del escritor ruso Fiódor Dostoyevski, 
            publicada en 1880. El escritor pasó casi dos años escribiendo la obra, que se publicó 
            de forma seriada en la revista literaria El Mensajero Ruso a lo largo de casi dos años, 
            entre enero de 1879 y noviembre de 1880. Cuatro meses después de su publicación, el autor falleció.
            Los hermanos Karamázov es una novela filosófica y psicológica ambientada en la Rusia del siglo XIX, 
            que entra profundamente en los debates éticos de Dios, el libre albedrío y la moralidad. Es un drama 
            espiritual de luchas morales relacionadas con la fe, la duda, el juicio y la razón, contra una Rusia 
            en proceso de modernización, con una trama que gira en torno al parricidio. Dostoyevski compuso gran 
            parte de la novela en la pequeña ciudad de Stáraya Rusa, que inspiró el escenario principal.​Desde su 
            publicación, ha sido aclamada como una de las obras más importantes de la literatura universal.`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },{
            img: 'card3.png',
            nombre: "La notte bianca",
            informacion:`La notte bianca è un'iniziativa generalmente promossa 
            a livello cittadino che consiste nell'organizzazione di vari eventi a 
            carattere culturale o d'intrattenimento, i quali si svolgono nell'arco 
            di una nottata. Spesso, questa iniziativa è accompagnata da un servizio 
            straordinario dei mezzi pubblici, dall'allestimento di spettacoli e dall'apertura 
            prolungata di negozi e musei, fino alle prime luci dell'alba.`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },{
            img: 'img16.png',
            nombre: "La guerra Fría",
            informacion:`La Guerra Fría fue un enfrentamiento político, económico, social, 
            ideológico, militar y propagandístico que tuvo lugar después de la Segunda Guerra 
            Mundial entre dos bloques principales: Occidental (capitalista) y Oriental (comunista). 
            Estos bloques estaban liderados por los Estados Unidos y la Unión Soviética, respectivamente. 
            El inicio de este periodo se remonta a 1945. Después de la Segunda Guerra Mundial, los Estados 
            Unidos temían la expansión del comunismo y buscaban frenar la influencia soviética en Europa. 
            En 1949 crearon la alianza militar conocida como la Organización del Tratado del Atlántico Norte 
            (OTAN). El objetivo principal de la OTAN era contrarrestar la influencia soviética y garantizar 
            la seguridad de los países miembros. En respuesta a la creación de la OTAN la Unión Soviética 
            estableció el Pacto de Varsovia en 1955. Este pacto militar fue una respuesta directa al bloque 
            occidental y buscaba fortalecer la cooperación entre los países comunistas. A lo largo de la 
            Guerra Fría, se produjeron varias crisis que aumentaron las tensiones entre ambos bloques. 
            Algunas de las crisis más destacadas incluyeron el bloqueo de Berlín de 1948-1949, la segunda 
            fase de la guerra civil china (1946-1949), la guerra de Corea (1950-1953), la crisis de Suez 
            de 1956, la insurrección húngara en el período del 23 de octubre al 10 de noviembre de 1956, 
             la crisis de Berlín de 1961 y la crisis de los misiles cubanos de 1962.`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },{
            img: 'img16.png',
            nombre: "nombre completo de la noticia sabias4",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },{
            img: 'img16.png',
            nombre: "nombre completo de la noticia sabias5",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia sabias6",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          },
          {
            img: 'img16.png',
            nombre: "nombre completo de la noticia sabias7",
            informacion:`Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Lorem
            ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna
            aliqua. Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.Lorem
            ipsum`,
            dia:"1",
            mes:"1",
            anio:"2023",
            categoria:"1"
            
          }
    ]

};

export {data};